import React, { JSX } from "react";
import { useTheme } from "../../context/ThemeContext";
import './Footer.scss'

const Footer = (): JSX.Element => {
  const decToHex: (dec: number) => string = (dec: number): string => {
    return dec.toString(16).padStart(2, '0');
  };

  const { backgroundImage, color } = useTheme()!;
  const styleColor: string = `#${decToHex(color?.r ?? 255)}${decToHex(color?.g ?? 255)}${decToHex(color?.b ?? 255)}`;
  return (
    <div className="footer" style={{ color: styleColor }}>
      <div style={{ backgroundColor: styleColor }}>
        <a href="https://www.bible.com/" target="_blank">YouVersion</a>; <a href="https://scripture.api.bible" target="_blank">API.Bible</a>; Background: <a href="https://unsplash.com" target="_blank">Unsplash</a>
      </div>
    </div>
  );
}

export default Footer;
