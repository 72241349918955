import React, { createContext, Dispatch, JSX, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from "react";

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface ThemeContextProps {
  backgroundImage: string;
  children: ReactNode;
}

const CACHED_COLOR: string = 'cachedColor';

/** Daily hash that changes daily. */
const dailyHash: string = ((): string => {
  const date = new Date();
  return `${date.setHours(0, 0, 0, 0)}`;
})();

const getAverageRGB: (data: Uint8ClampedArray) => RGBColor = (data: Uint8ClampedArray): RGBColor => {
  const blockSize = 5;
  const rgb: RGBColor = { r: 0, g: 0, b: 0 };
  let i = -4;
  let length;
  let count = 0;


  length = data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data[i];
    rgb.g += data[i + 1];
    rgb.b += data[i + 2];
  }

  // ~~ is used for floor values.
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
}

const ImageInfoExtractor: React.FC<{ src: string, setColor: Dispatch<SetStateAction<RGBColor | null>> }> = ({ src, setColor }): JSX.Element => {
  const imgRef = useRef<HTMLImageElement>(null);

  const handleLoad = async () => {
    try {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const context: CanvasRenderingContext2D | null = canvas.getContext && canvas.getContext('2d');
      let width: number = 0;
      let height: number = 0;

      if (imgRef.current) {
        width = canvas.width = imgRef.current.naturalWidth || imgRef.current.offsetWidth || imgRef.current.width || 0;
        height = canvas.height = imgRef.current.naturalHeight || imgRef.current.offsetHeight || imgRef.current.height || 0;
        context?.drawImage(imgRef.current, 0, 0);
      }

      // Retrieve the data of the image.
      const data = context?.getImageData(0, 0, width, height)?.data ?? undefined;

      if (data) {
        const color = await new Promise<RGBColor>((resolve: (value: RGBColor) => void): void => {
          resolve(getAverageRGB(data));
        });
        setColor(color);
      }

      // Remove the temporary img element from the DOM.
      // imgRef.current?.remove();
    } catch (error) {
      console.error('Error loading the image:', error);
    }
  };

  return (
    <img ref={imgRef} src={src} onLoad={handleLoad} style={{ display: 'none' }} />
  );
};

const ThemeContext = createContext<{ backgroundImage: string, color: RGBColor | null } | undefined>(undefined);

const ThemeProvider: React.FC<ThemeContextProps> = ({ backgroundImage, children }) => {
  const [color, setColor] = useState<RGBColor | null>(null);

  useEffect((): void => {
    document.body.style.backgroundImage = `url("${backgroundImage}?${dailyHash}")`;
  }, [backgroundImage]);

  useEffect((): void => {
    const storedData = localStorage.getItem(CACHED_COLOR);
    if (storedData) {
      const { timestamp, color } = JSON.parse(storedData);
      const oneDayMs = 24 * 60 * 60 * 1000;
      const isDataValid = Date.now() - timestamp < oneDayMs;
      if (isDataValid) {
        setColor(color);
      }
    }
  }, []);

  useEffect((): void => {
    if (color) {
      // Update local storage.
      localStorage.setItem(
        CACHED_COLOR,
        JSON.stringify({ timestamp: Date.now(), color })
      );
    }
  }, [color]);

  let imageInfo: JSX.Element | undefined = undefined;

  if (!color) {
    imageInfo = (<ImageInfoExtractor src={backgroundImage} setColor={setColor} />);
  }


  return (
    <ThemeContext.Provider value={{ backgroundImage, color }}>
      {children}
      {imageInfo}
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { type RGBColor, ThemeContext, ThemeProvider, useTheme };
