import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import './VerseOfDay.scss';

interface Verse {
  id: string;
  text: string;
  reference: string;
}

/** Base url for the bible chapter link. */
const BIBLE_BASE_URL = `https://my.bible.com/de/bible/149`;

declare var verses: Array<Verse>;

/**
 * Extracts the chapter out of a given verse id.
 * @returns {string} the extracted chapter or empty string if it could not parse the id.
 */
const extractChapter: (id: string) => string = (id: string): string => {
  const parts = id?.split('.') ?? [];
  if (parts.length > 1) {
    return `${parts[0]}.${parts[1]}`;
  }
  return '';
}

/**
 * Build the verse of the day using the `verses` variable.
 */
const buildVerseOfDay: () => { verse: Verse, chapter: string } = () => {
  let id: string = '';
  let text: string = '';
  let reference: string = '';
  let lastVerseNumber: number = 0;

  if (verses) {
    // Loop over the verses.
    for (const verse of verses) {
      // Add each text to the verse of the day.
      text += verse.text;

      if (!reference) {
        // if there is no reference yet, use the whole verse reference as base.
        id = verse.id;
        reference = verse.reference;
      } else {
        // otherwhise save the verse as the las verse, this will be the last verse number of the whole verse of the day.
        lastVerseNumber = +verse.reference.split(':')[1];
      }
    }

    if (lastVerseNumber) {
      const suffix = `-${lastVerseNumber}`;
      reference += suffix;
      id += suffix;
    }
  }

  return { verse: { id, text, reference }, chapter: extractChapter(id) };
}

const VerseOfDay = (): React.JSX.Element => {
  const { verse, chapter } = buildVerseOfDay();
  const [bibleRef, setBibleRef] = useState('');
  const [tooltip, setTooltip] = useState('');

  useEffect((): void => {
    setBibleRef(`${BIBLE_BASE_URL}/${chapter}`);
    setTooltip(`Leer ${verse.reference.split(':')[0]?.trim()}`);
  }, []);

  return (
    <div className="verse-of-day glass-effect box-shadow">
      <div className="text font-size">{verse.text}</div>
      <a href={bibleRef} data-tooltip-id="reference-anchor" data-tooltip-content={tooltip} target={"_blank"}>
        <div className="reference font-size">{verse.reference}</div>
      </a>
      <Tooltip id="reference-anchor" />
    </div>
  );
}

export default VerseOfDay;
