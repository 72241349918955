import React, { useContext, useState, JSX } from 'react';
import MainComponent from './components/MainComponent/MainComponent';
import { ThemeProvider } from './context/ThemeContext';


const App = (): JSX.Element => {
  return (
    <ThemeProvider backgroundImage='images/background.jpg'>
      <MainComponent />
    </ThemeProvider>
  );
}

export default App;
