import React, { JSX } from "react";
import Footer from "../Footer/Footer";
import VerseOfDay from "../VerseOfDay/VerseOfDay";
import './MainComponent.scss';

const MainComponent = (): JSX.Element => {
  return (
    <div className="main-component">
      <VerseOfDay />
      <Footer />
    </div>
  );
};

export default MainComponent;
